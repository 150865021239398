import React from "react"
import { Link } from "gatsby"
import styles from "./navigation.module.css"

const Navigation = () => (
  <div className={styles.disableSelect}>
    <ul className={styles.list}>
      <li>
        <Link
          to="/"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          work on canvas
        </Link>
      </li>
      <li>
        <Link
          to="/work-paper"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          work on paper
        </Link>
      </li>
      <li>
        <Link
          to="/cv"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          cv
        </Link>
      </li>
      <li>
        <Link
          to="/text"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          text
        </Link>
      </li>
      <li>
        <Link
          to="/contact-imprint"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          contact/imprint
        </Link>
      </li>
      <li>
        <Link
          to="/datenschutz"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          datenschutz
        </Link>
      </li>
    </ul>
  </div>
)

export default Navigation
