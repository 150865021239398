/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import { Link } from "gatsby"

import Navigation from "./navigation"
import "typeface-noto-sans"
import "./copied-layout.css"
import styles from "./layout.module.css"

class Layout extends Component {
  render() {
    return (
      <div className={styles.outerWrapper}>
        <input
          type="checkbox"
          id="css-hack-checkbox"
          style={{ position: "absolute", left: "-999px" }}
        ></input>
        <div className={styles.mobileMenu}>
          <Navigation />
        </div>
        <div className={styles.header}>
          <Link className={styles.title} to="/">
            CHRISTINA BAUMANN
          </Link>
          <label
            className={styles.menuToggle}
            htmlFor="css-hack-checkbox"
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            Menu
          </label>
        </div>

        <div className={styles.layoutWrapper}>
          <div className={styles.desktopMenu}>
            <Navigation />
          </div>
          <div className={styles.mainArea}>
            <main>{this.props.children}</main>
          </div>
        </div>
      </div>
    )
  }
}

/* const Layout = ({ children }) => {
  return (
    <div className={styles.outerWrapper}>
      <input
        type="checkbox"
        id="css-hack-checkbox"
        style={{ position: "absolute", left: "-999px" }}
      ></input>
      <div className={styles.mobileMenu}>
        <Navigation />
      </div>
      <div className={styles.header}>
        <Link className={styles.title} to="/">
          CHRISTINA BAUMANN
        </Link>
        <label className={styles.menuToggle} htmlFor="css-hack-checkbox">
          Menu
        </label>
      </div>

      <div className={styles.layoutWrapper}>
        <div className={styles.desktopMenu}>
          <Navigation />
        </div>
        <div className={styles.mainArea}>
          <main>{children}</main>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  leftSlot: PropTypes.node,
} */

export default Layout
